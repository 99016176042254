.Layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .Content {
    flex: 1;
  }
  
  .Footer {
    margin-top: auto;
  }
  