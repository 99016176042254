.carousel-container {
  /* width: 100%; */
  /* max-width: 60%; */
  margin: 0 auto;
}

.carousel-image {
  width: 100%;
  height: auto;
}

.slick-arrow {
  position: absolute;
  display: block;
  /* margin-top: 50%; */
  /* transform: translateY(-50%); */
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s;
}

.slick-arrow:hover {
  color: #000;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}
